<!--
 * @Description:空白页面获取code codeQuery 线上
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-07-02 14:41:28
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'></div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {}
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    // alert(navigator.userAgent.toLowerCase(), '浏览器类型')
    const currentHref = window.location.href
    if (navigator.userAgent.toLowerCase().indexOf('alipay') !== -1) {
      // 安卓支付宝扫码 https://officialaccount.cqybxm.com/#/?page=alipayCode&orderSequence=123
      this.$router.push({
        name: 'alipayCode',
        query: { orderSequence: currentHref.split('orderSequence=')[1] }
      })
    } else if (navigator.userAgent.toLowerCase().indexOf('wechat') !== -1) {
      // 安卓微信扫码
      if (currentHref.indexOf('orderSequence') !== -1) {
        const orderSequence = currentHref.split('orderSequence=')[1]
        location.replace('https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxaa4bd9cc425e1619&redirect_uri=https://officialaccount.cqybxm.com/%23/partrolPersonPage/?orderSequence=' + orderSequence + '&response_type=code&scope=snsapi_base&state=1#wechat_redirect'
        )
      } else {
        this.getWeChatCode()
      }
    } else if (navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1) {
      // 苹果微信扫码
      if (currentHref.indexOf('orderSequence') !== -1) {
        const orderSequence = currentHref.split('orderSequence=')[1]
        location.replace('https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxaa4bd9cc425e1619&redirect_uri=https://officialaccount.cqybxm.com/%23/partrolPersonPage/?orderSequence=' + orderSequence + '&response_type=code&scope=snsapi_base&state=1#wechat_redirect'
        )
      } else {
        this.getWeChatCode()
      }
    }
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 获取code
    getWeChatCode () {
      var page = this.getQueryString('page')
      location.replace(
        'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxaa4bd9cc425e1619&redirect_uri=https://officialaccount.cqybxm.com/%23/' + page + '&response_type=code&scope=snsapi_base&state=1#wechat_redirect'
      )
    },
    getQueryString (name, search) {
      search = search || window.location.search.substr(1) || window.location.hash.split('?')[1]
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      const r = search.match(reg)
      if (r != null) return unescape(r[2]); return null
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
}
</style>
